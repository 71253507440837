<template>
  <el-scrollbar>
    <div class="elv-reports-menu">
      <li v-for="(item, index) in financialsMenuList" :key="index" style="display: flex" @click="onNodeClick(item)">
        <div
          :class="{
            'elv-reports-menu-item__selected': currentSelectedMenu(item.financialId)
          }"
          class="elv-reports-menu-item"
        >
          <span>{{ transformI18n(item.name) }}</span>
        </div>
      </li>

      <div class="elv-reports-menu-right-button">
        <div
          v-if="!regeneratedRef?.regenerating && reportStore.entityDetail?.guideStep > 2"
          class="elv-reports-menu-right"
        >
          <el-tooltip
            ref="elvTableTipsRef"
            :show-arrow="false"
            effect="light"
            placement="top"
            popper-class="elv-financials-header-tips"
            :show-after="500"
            :disabled="!isOperationReport"
          >
            <!-- class="elv-reports-menu-right__button" -->
            <elv-button
              :disabled="regeneratedRef?.regenerating"
              width="147"
              height="32"
              @click="regeneratedRef?.onClickedRegenerate"
            >
              <div v-if="regeneratedRef?.regenerating" class="elv-reports-regenerating">
                <span>{{ t('report.regenerating') }}</span>
              </div>
              <template v-else>
                {{ t('report.regenerateReports') }}
              </template>
            </elv-button>
            <template #content>
              <div class="elv-table-tips-content">{{ t('report.regenerateTheReports') }}</div>
            </template>
          </el-tooltip>
        </div>
      </div>
    </div>
    <h3 v-if="reportStore.entityDetail?.guideStep > 2" class="elv-report-title">{{ props.title }}</h3>
    <Regenerated ref="regeneratedRef" :type="props.type" />
  </el-scrollbar>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import { financialsMenuList } from '@/config/reports'
import Regenerated from '../Project/components/Regenerated.vue'
import { useReportStore } from '@/stores/modules/reports/index'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  }
})

const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const reportStore = useReportStore()
const selectedMenu = ref('reports-balance-sheet')
const regeneratedRef = ref()

const currentSelectedMenu = computed(() => (financialId: string) => {
  const result = financialId === selectedMenu.value
  return result
})

const balanceSheetTabWidth = computed(() => {
  return locale.value === 'en' ? '122.63px' : '97px'
})
const incomeStatementTabWidth = computed(() => {
  return locale.value === 'en' ? '149.47px' : '71px'
})
const cashFlowStatementTabWidth = computed(() => {
  return locale.value === 'en' ? '166.67px' : '97px'
})
const holdingsTabWidth = computed(() => {
  return locale.value === 'en' ? '158px' : '71px'
})
const restrictionsTabWidth = computed(() => {
  return locale.value === 'en' ? '104px' : '71px'
})
const rollForwardTabWidth = computed(() => {
  return locale.value === 'en' ? '106px' : '97px'
})

const regeneratingBg = computed(() => {
  if (regeneratedRef.value?.regenerating) {
    return '#EEF4FB'
  }
  return '#ffffff'
})

const isShowRegeneratedInfo = computed(() => {
  return regeneratedRef.value?.isShowRegeneratedInfo
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

const isOperationReport = computed(() => {
  if (!reportStore.entityDetail?.operationTime) return false
  return (
    !dayjs(reportStore.entityDetail?.operationTime).isBefore(reportStore.entityDetail?.reportTime) ||
    (reportStore.entityDetail?.operationTime && !reportStore.entityDetail?.reportTime)
  )
})

const onNodeClick = (data: any) => {
  switch (data.financialId) {
    case 'disclosure':
      return
    case 'sources':
      if (!currentEntityPermission.value?.dataSource?.view) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
    case 'transactions':
      if (!currentEntityPermission.value?.transaction?.viewList) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
    default:
      if (!currentEntityPermission.value?.report?.view) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      break
  }
  selectedMenu.value = data.financialId
  router.push({ name: `reports-${data.financialId}` })
}

defineExpose({ isShowRegeneratedInfo })

watchEffect(() => {
  financialsMenuList.value.forEach((item) => {
    if (`reports-${item.financialId}` === route.name) {
      selectedMenu.value = item.financialId
    }
  })
})
</script>
<style lang="scss" scoped>
.elv-reports-menu {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  margin: 20px 0 16px 0px;
  padding-bottom: 16px;
  padding-left: 20px;
  border-bottom: 1px solid #dde1e6;
  position: relative;
  @media screen and (max-width: 820px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  @media screen and (max-width: 390px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  li {
    &:nth-child(1) .elv-reports-menu-item {
      width: v-bind('balanceSheetTabWidth');
    }

    &:nth-child(2) .elv-reports-menu-item {
      width: v-bind('incomeStatementTabWidth');
    }

    &:nth-child(3) .elv-reports-menu-item {
      width: v-bind('cashFlowStatementTabWidth');
    }

    &:nth-child(4) .elv-reports-menu-item {
      background: #dde1e6;
      width: 1px;
      height: 16px;
      margin: 10px 16px 0;
    }

    &:nth-child(5) .elv-reports-menu-item {
      width: v-bind('holdingsTabWidth');
    }

    &:nth-child(6) .elv-reports-menu-item {
      width: v-bind('restrictionsTabWidth');
    }

    &:nth-child(7) .elv-reports-menu-item {
      width: v-bind('rollForwardTabWidth');
    }
  }

  .elv-reports-menu-item {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $elv-theme-text-color;
    box-sizing: border-box;
    background-color: transparent;
    position: relative;

    svg {
      fill: #838d95;
    }

    &::after {
      content: '';
      pointer-events: none;
      bottom: 0px;
      left: 50%;
      position: absolute;
      width: 0%;
      height: 1px;
      background-color: #1343bf;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 400ms;
      transition-property: width, left;
    }
  }

  .elv-reports-menu-item:not(.elv-reports-menu-item__selected):hover {
    span {
      color: #1343bf;
      cursor: pointer;
    }

    svg {
      fill: #1343bf;
    }

    &:hover::after {
      width: calc(100% - 32px);
      left: 16px;
    }
  }

  .elv-reports-menu-item__selected {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    color: $elv-color-394048;
    background: $elv-color-EDF0F3 !important;
    border-radius: 23px;
    cursor: default;

    &:hover {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 13px;
      color: $elv-color-394048;
    }
  }
}

.elv-report-title {
  font-family: 'Plus Jakarta Sans';
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-left: 22px;
  padding-bottom: 4px;
}

.elv-reports-menu-right-button {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
}

.elv-reports-menu-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.elv-reports-menu-right__button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 152px;
  height: 32px;
  border: 1px solid #dde1e6;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;
  background-color: v-bind('regeneratingBg');

  svg {
    fill: #1e2024;
    margin-right: 8px;
  }

  &:hover {
    color: #1343bf;
    border: 1px solid #7596eb;

    svg {
      fill: #1343bf;
    }
  }

  .elv-reports-regenerating {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      animation: loading-rotate 2s linear infinite;
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #1e2024;
    }
  }
}
</style>
